@import "../main/abstracts/index";
.section--top-topics-custom{
    margin: 80px 0;
    header h2{
        text-align: center;
        position: relative;
        @media(max-width: 600px){
            text-align: right;
        }
        &:before{
            content: "";
            width: 90px;
            height: 50px;
            background-image: url('/assets/website/img/eber.svg');
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            left: 0;
            bottom: 13px;
            @media(max-width: 600px){
                width: 38px;
                height: 21px;
                bottom: 6px;
            }
        }
        &:after{
            content: "";
            width: 50%;
            left: 110px;
            position: absolute;
            bottom: 13px;
            height: 1px;
            background-color: $color__black;
            @media(max-width: 600px){
                bottom: 6px;
                left: 41px;
            }
        }
        span{
            position: relative;
            z-index: 1;
            background-color: $color__white;
            padding-left: 20px;
            @media(max-width: 600px){
                padding-left: 0;
            }
        }
    }
}
.top-topics-custom{
    position: relative;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // gap: 30px;

    &__row{
        // width: calc(50% - 15px);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 0px;      
        @media(max-width: 1100px){
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(3, 1fr);
            grid-column-gap: 30px;
            grid-row-gap: 30px;
        }
        @media(max-width: 767px){
            grid-column-gap: 15px;
            grid-row-gap: 15px;
        }
        @media(max-width: 767px){
            display: block;
        }
        .top-topics-custom__item{
            // width: calc(50% - 15px);
            @media(max-width: 767px){
                margin-top: 15px;
            }
            aspect-ratio: unset;
            a{
                height: auto;
                aspect-ratio: 1;
            }
            @media(max-width: 600px){
                width: 100%;
            }
            &:first-of-type{
                grid-area: 1 / 1 / 2 / 3;
                @media(max-width: 1100px){
                    grid-area: 1 / 1 / 3 / 5;
                }
            }
            &:nth-child(2){
                grid-area: 1 / 3 / 2 / 4;
                @media(max-width: 1100px){
                    grid-area: 3 / 1 / 4 / 3; 
                }
            }
            &:nth-of-type(3){
                grid-area: 1 / 4 / 2 / 5; 
                @media(max-width: 1100px){
                    grid-area: 3 / 3 / 4 / 5; 
                }
            }
        }
        &:nth-child(even){
            margin-top: calc(-25% + 30px);
            @media(max-width: 1100px){
                margin-top: 30px;
            }
            @media(max-width: 767px){
                margin-top: 15px;
            }
            .top-topics-custom__item{
                // width: calc(50% - 15px);
                aspect-ratio: unset;
                a{
                    height: auto;
                    aspect-ratio: 1;
                }
                @media(max-width: 600px){
                    width: 100%;
                }
                &:first-of-type{
                    grid-area: 1 / 1 / 2 / 2;
                    display: flex;
                    align-items: flex-end;
                    a{
                        width: 100%;
                        position: relative;
                    }
                    @media(max-width: 1100px){
                        grid-area: 1 / 1 / 2 / 3;
                    }
                    
                }
                &:nth-child(2){
                    grid-area: 1 / 2 / 2 / 3;
                    display: flex;
                    align-items: flex-end;
                    a{
                        width: 100%;
                        position: relative;
                    }
                    @media(max-width: 1100px){
                        grid-area: 1 / 3 / 2 / 5;
                    }
                }
                &:nth-of-type(3){
                    grid-area: 1 / 3 / 2 / 5; 
                    @media(max-width: 1100px){
                        grid-area: 2 / 1 / 4 / 5;
                    }
                }
               
            }
        }
        &:nth-child(odd){
            .top-topics-custom__item:first-of-type{
                // width: calc(50% - 15px);
                @media(max-width: 600px){
                    width: 100%;
                }

                &:not(:last-of-type){
                    // width: 100%;
                    aspect-ratio: unset;

                    .top-topics-custom__image{
                        img{
                            aspect-ratio: 4/3;
                            object-fit: cover;
                            @media(max-width: 600px){
                                aspect-ratio: 1;
                            }
                        }
                    }
                    .top-topics-custom__caption{
                        max-width: 80%;
                        @media(max-width: 600px){
                            max-width: 100%;
                        }
                    }
                }

            }
            &:last-of-type{
                // width: 100%;
                // flex-direction: row;
                // justify-content: flex-start;
                // align-items: flex-start;
                .top-topics-custom__item{
                    width: calc(25% - 22.5px);
                    aspect-ratio: 1;
                    @media(max-width: 1200px){
                        width: calc(50% - 22.5px);
                    }
                    @media(max-width: 600px){
                        width: 100%;
                    }

                }
                .top-topics-custom__item:first-of-type{
                    width: calc(25% - 22.5px);
                    @media(max-width: 1200px){
                        width: calc(50% - 22.5px);
                    }
                    @media(max-width: 600px){
                        width: 100%;
                    }

                    &:not(:last-of-type){
                        width: calc(50% - 15px);
                        aspect-ratio: unset;
                        @media(max-width: 1200px){
                            width: 100%;
                        }

                        .top-topics-custom__image{
                            img{
                                aspect-ratio: 4/3;
                                object-fit: cover;
                                @media(max-width: 600px){
                                    aspect-ratio: 1;
                                }
                            }
                        }
                        .top-topics-custom__caption{
                            max-width: 80%;
                            @media(max-width: 600px){
                               max-width: 100%;
                            }
                        }
                    }

                }
            }
        }
    }
    &__item{
        position: relative;
        
        // aspect-ratio: 1;
        &:not(.top-topics-custom__item--has-image){
            background-color: $color__primary;
            &:before{
                content: "";
                aspect-ratio: 1/1;
                height: 50%;
                max-width: 166px;
                position: absolute;
                right: 0;
                bottom: 0;
                background-image: url('/assets/website/img/eber_half.svg');
                filter: $filter__white;
                background-repeat: no-repeat;
                background-position: bottom right;
            }
            a{

                @include hover-focus-visible{
                    h3{
                        text-decoration: underline;
                    }
                    p{
                        &[aria-hidden="true"]{
                            border: 1px solid $color__white;
                            background-color: $color__primary;
                        }
                    }
                }
            }
        }
        a{
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            display: block;
            .top-topics-custom__image{
                height: 100%;
                figure,
                picture,
                img{
                    width: 100%;
                    height: 100%;
                }
                img{
                    object-fit: cover;
                }
            }
            @include hover-focus-visible{
                h3{
                    text-decoration: underline;
                }
                p{
                    &[aria-hidden="true"]{
                        background-color: $color__primary;
                    }
                }
                .top-topics-custom__image{
                    img{
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    &__image{
        overflow: hidden;
        img{
            @include transition;
        }
    }
    &__caption{
        border-radius: 4px;
        position: absolute;
        left: 10px;
        top: 10px;
        right: 10px;
        background-color: $color__white;
        padding: 24px;
        p{
            font-weight: $font-weight-base;
            &[aria-hidden="true"]{
                background-color: $color__black;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0rem;
                bottom: -40px;
                border-radius: 4px;
                transform: translate(-50%, -50%);
                img{
                    filter: $filter__white
                }
            }
        }
    }
    .pimcore_editable_image{
        width: 100%!important;
        height: 100%!important;
    }
    .pimcore_editable{
        .top-topics-custom__caption{
            top: 25px;
        }
    }
}
